import countries from "@/countries.json";
export const countryStateMixin = {
  data() {
    return {
      countries: countries,
    };
  },
  methods: {
    getCountries() {
      return ["", ...this.countries.map((c) => c.countryName)];
    },

    getStates(country) {
      if (!country) return [""];
      const countryData = this.countries.find((c) => c.countryName === country);
      return countryData
        ? ["", ...countryData.regions.map((r) => r.name)]
        : [""];
    },

    getAbbrvMap(country) {
      if (!country) return {};

      const countryData = this.countries.find((c) => c.countryName === country);
      if (!countryData) return {};

      return countryData.regions.reduce((map, region) => {
        map[region.name.toLowerCase()] = region.shortCode.toLowerCase();
        return map;
      }, {});
    },

    getStateAbbrv(country, state) {
      let stateAbbrv = "";
      if (country && state) {
        stateAbbrv = this.countries
          .filter((s) => country === s.countryName)[0]
          .regions.filter((r) => state === r.name)[0].shortCode;
      }
      return stateAbbrv;
    },
  },
};
export default countryStateMixin;
